import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter  } from 'react-router-dom';
import { RouterConfig } from './rounterConfig';
import ScrollToTop from 'components/common/scrollToTop';
import './assets/styles/index.sass';
import 'react-app-polyfill/stable';


render(
  <BrowserRouter >
    <RouterConfig />
    <ScrollToTop />
  </BrowserRouter >, 
  document.getElementById('root')
);