import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = (props) => {
  const { history, match } = props;
  const [path, setPath] = useState(match.path);

  useEffect(() => {
    const unlisten = history.listen((e) => {
      if (path !== e.pathname) {
        window.scrollTo(0, 0);
        setPath(e.pathname);
      }   
    });
    return () => {
      unlisten();
    }
  });

  return null;
}

export default withRouter(ScrollToTop);