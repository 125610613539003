import React from 'react'; 
import LoaderUrl from 'assets/img/icons/loader.svg';
import './loader.sass';


const Loader = () => {
  return (
    <div className="loader">
      <img src={LoaderUrl} alt="Loader" />
    </div>
  );
}

export default Loader;