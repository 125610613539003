import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loader from 'components/common/loader/loader';
// Pages
const Index = lazy(() => import('./pages/index/index'));
const Articles = lazy(() => import('./pages/articles/articles'));
const Article = lazy(() => import('./pages/article/article'));
const Page404 = lazy(() => import('./pages/page404/page404'));


export const RouterConfig = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route path="/articles" component={Articles} />
        <Route path="/article/:name" component={Article} />
        <Route component={Page404} />
      </Switch>
    </Suspense>
  );
}